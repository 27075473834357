<template>
  <div class="flex flex-col">
    <p class="flex flex-row items-center text-lg font-semibold">
      <Icon name="mdi:alert-box" class="text-4xl text-a-secondary px-2" />
      Are you sure, you want to deactivate this announcement?
    </p>
    <p class="text-base pt-3 pb-5">
      By Deactivating, you will never be able to get this announcement back
      active again. This will cancel any subscription or purchases made for this
      specific news announcement.
    </p>
    <div class="flex flex-row w-full">
      <BaseButton
          text-size-class="text-sm md:text-base"
          width-class="w-1/2"
          primary-color-class="a-error"
          text-color-class="white"
          margin-classes="my-2 mx-1"
          @click.native="deactivateNewsAnnouncement()"
          :loading="deactivateNewsAnnouncementLoading"
          loadingColor="#EF4444"
      >
        Yes, Deactivate
      </BaseButton>
      <BaseButton
          isTextOnly
          text-color-class="a-neutral-light"
          width-class="w-1/2"
          text-size-class="text-sm md:text-base"
          margin-classes="my-2 mx-1"
          @click.native="$emit('closeModal')"
      >
        No
      </BaseButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { DashboardNewsAnnouncement } from '~/types';

const deactivateNewsAnnouncementLoading = ref(false);


const props = defineProps<{
  classifiedAd: DashboardNewsAnnouncement | null;
}>();

const emit = defineEmits(['closeModal']);

async function deactivateNewsAnnouncement() {
  deactivateNewsAnnouncementLoading.value = true;
  await useNewsDataManager().deactivateCurrentDashboardNewsAnnouncement();
  emit('closeModal');
  deactivateNewsAnnouncementLoading.value = false
}
</script>

<style></style>
